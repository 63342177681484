import { level } from "./level1";
import { level as level2 } from "./level2";
import { level as level3 } from "./level3";
import { level as level4 } from "./level4";
import { level as level5 } from "./level5";

export const levels = [
    level,
    level2,
    level3,
    level4,
    level5,
]