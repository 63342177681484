import React from "react";
import { Game } from "../../core/game2";

interface IBottomPanelProps{

}

export function BottomPanel({}: IBottomPanelProps) {
    return (
      <div className="game-bottom-wrapper">
        bottom panel
      </div>
    )
}