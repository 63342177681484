export const level = {
    "moves": 30,
    "field": [
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "-",
            "-",
            "-",
            "-",
            "-",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ],
        [
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+",
            "+"
        ]
    ],
    "objects": [
        {
            "position": {
                "x": 5,
                "y": 11
            },
            "type": 5
        },
        {
            "position": {
                "x": 6,
                "y": 11
            },
            "type": 5
        },
        {
            "position": {
                "x": 7,
                "y": 11
            },
            "type": 5
        },
        {
            "position": {
                "x": 8,
                "y": 11
            },
            "type": 5
        },
        {
            "position": {
                "x": 8,
                "y": 10
            },
            "type": 5
        },
        {
            "position": {
                "x": 7,
                "y": 10
            },
            "type": 5
        },
        {
            "position": {
                "x": 6,
                "y": 10
            },
            "type": 5
        },
        {
            "position": {
                "x": 5,
                "y": 10
            },
            "type": 5
        },
        {
            "position": {
                "x": 2,
                "y": 6
            },
            "type": 10
        },
        {
            "position": {
                "x": 2,
                "y": 5
            },
            "type": 10
        },
        {
            "position": {
                "x": 1,
                "y": 5
            },
            "type": 10
        },
        {
            "position": {
                "x": 1,
                "y": 6
            },
            "type": 10
        },
        {
            "position": {
                "x": 1,
                "y": 7
            },
            "type": 10
        },
        {
            "position": {
                "x": 2,
                "y": 7
            },
            "type": 10
        },
        {
            "position": {
                "x": 3,
                "y": 7
            },
            "type": 10
        },
        {
            "position": {
                "x": 3,
                "y": 6
            },
            "type": 10
        },
        {
            "position": {
                "x": 3,
                "y": 5
            },
            "type": 10
        },
        {
            "position": {
                "x": 3,
                "y": 4
            },
            "type": 10
        },
        {
            "position": {
                "x": 2,
                "y": 4
            },
            "type": 10
        },
        {
            "position": {
                "x": 1,
                "y": 4
            },
            "type": 10
        }
    ]
}